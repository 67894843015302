import _ from "lodash-contrib";
import moment from "moment";

const Component = {
  renderPrettyValue(val) {
    if (val === null || val === undefined) {
      return "-";
    } else if (_.isInteger(val)) {
      return val;
    } else if (_.isFloat(val)) {
      return Math.floor(val * 100) / 100; // Floor used instead of Round so user is not confused why transaction with risk 3.999 (rounded to 4.0) was not rejected
    } else if (_.isString(val)) {
      if (moment(val).isValid()) {
        return moment(val).format("YYYY-MM-DD HH:mm:ss Z");
      } else {
        return val;
      }
    } else if (_.isBoolean(val)) {
      return val ? "YES" : "NO";
    } else if (_.isArray(val)) {
      return val.map((v) => this.renderPrettyValue(v)).join(", ");
    } else {
      return "Object " + JSON.stringify(val);
    }
  },
};

export default Component;
