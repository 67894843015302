import { Link } from "react-router-dom";

const Component = ({}) => {
  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Score</td>
            <td>Driven by the Hotspot merchant table</td>
          </tr>
        </tbody>
      </table>
      <Link to="/config/hotspot">View detail</Link>
    </>
  );
};

export default Component;
