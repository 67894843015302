import { useImmer } from "use-immer";
import { useEffect } from "react";
import { Link } from "react-router-dom";

const Component = ({ config, onChange }) => {
  const [data, updateData] = useImmer(config);

  useEffect(() => {
    updateData(config);
  }, [config]);

  useEffect(() => {
    onChange(data);
  }, [data]);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Fuel guard enabled score</td>
            <td>
              <input
                type="number"
                value={data.fuelGuardScore}
                onChange={(e) =>
                  updateData((d) => {
                    d.fuelGuardScore = e.target.value < 0 ? e.target.value : 0;
                  })
                }
              />
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
