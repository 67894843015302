import { produce } from "immer";
import FrontendConstant from "../../constant/FrontendConstant";
import ApiConnector from "../../connector/ApiConnector";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import Multiselect from "../Multiselect";

const Component = ({ jobParam, onChange }) => {
  const [ruleInfoItems, updateRuleInfoItems] = useImmer([]);

  useEffect(async () => {
    const ruleInfo = await ApiConnector.getRuleInfo();
    updateRuleInfoItems(ruleInfo.ruleInfoItems);
  }, []);

  return (
    <>
      <table className="config-table">
        <thead>
          <tr>
            <th>Attribute</th>
            <th>Value</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Trn date min.</td>
            <td>
              <input
                type="datetime-local"
                value={jobParam.trnDateMin.replace("Z", "")}
                max={jobParam.trnDateMax}
                onChange={(e) =>
                  onChange(
                    produce(jobParam, (d) => {
                      d.trnDateMin = e.target.value + "Z";
                      console.log(e.target.value);
                    }),
                  )
                }
                disabled={!onChange}
              />
            </td>
          </tr>
          <tr>
            <td>Trn date max.</td>
            <td>
              <input
                type="datetime-local"
                value={jobParam.trnDateMax.replace("Z", "")}
                min={jobParam.trnDateMin}
                onChange={(e) =>
                  onChange(
                    produce(jobParam, (d) => {
                      d.trnDateMax = e.target.value + "Z";
                    }),
                  )
                }
                disabled={!onChange}
              />
            </td>
          </tr>
          <tr>
            <td>False positives max.</td>
            <td>
              <input
                type="number"
                value={jobParam.falsePositivesMax}
                min={0}
                onChange={(e) =>
                  onChange(
                    produce(jobParam, (d) => {
                      d.falsePositivesMax = e.target.value;
                    }),
                  )
                }
                disabled={!onChange}
              />
            </td>
          </tr>
          <tr>
            <td>True positives min.</td>
            <td>
              <input
                type="number"
                value={jobParam.truePositivesMin}
                min={0}
                onChange={(e) =>
                  onChange(
                    produce(jobParam, (d) => {
                      d.truePositivesMin = e.target.value;
                    }),
                  )
                }
                disabled={!onChange}
              />
            </td>
          </tr>
          <tr>
            <td>Run duration seconds max.</td>
            <td>
              <input
                type="number"
                value={jobParam.runDurationSecondsMax}
                onChange={(e) =>
                  onChange(
                    produce(jobParam, (d) => {
                      d.runDurationSecondsMax =
                        e.target.value >
                        FrontendConstant.lambdaRunDurationSecondsMax
                          ? FrontendConstant.lambdaRunDurationSecondsMax
                          : e.target.value;
                    }),
                  )
                }
                disabled={!onChange}
              />
            </td>
          </tr>
          <tr>
            <td>Threshold</td>
            <td>
              <input
                type="number"
                value={jobParam.threshold}
                max={840}
                onChange={(e) =>
                  onChange(
                    produce(jobParam, (d) => {
                      d.threshold = e.target.value;
                    }),
                  )
                }
                disabled={!onChange}
              />
            </td>
          </tr>
          <tr>
            <td>Weights found max.</td>
            <td>
              <input
                type="number"
                value={jobParam.foundWeightsMax}
                max={840}
                onChange={(e) =>
                  onChange(
                    produce(jobParam, (d) => {
                      d.foundWeightsMax =
                        e.target.value > 1000 ? 1000 : e.target.value;
                    }),
                  )
                }
                disabled={!onChange}
              />
            </td>
          </tr>
          <tr>
            <td>Ignored rule score column names</td>
            <td>
              <Multiselect
                values={ruleInfoItems.map((rii) => rii.ruleScoreColumnName)}
                selectedValues={
                  jobParam.ignoredRuleScoreColumns
                    ? jobParam.ignoredRuleScoreColumns
                    : []
                }
                onChange={(e) =>
                  onChange(
                    produce(jobParam, (d) => {
                      d.ignoredRuleScoreColumns = e;
                    }),
                  )
                }
              ></Multiselect>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default Component;
